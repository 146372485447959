/* Button style */

.btn-add {
    color: #6CAE40;
    background-color: white;
}

.btn-add:hover {
    background-color: #578836;
    color: white;
}


.btn-edit {
    color: white;
    background-color: #cecc47;
}

.btn-edit:hover {
    color: white;
    background-color: #b8b63f;
}


.btn-edit-outlined {
    background-color: white;
    color: #6d6c25;
}

.btn-edit-outlined:hover {
    background-color: #b8b63f;
    color: white;
}



.btn-send {
    color: white;
    background-color: #ce8a47;
}

.btn-send:hover {
    color: white;
    background-color: #e7892a;
}

.btn-send-outlined {
    background-color: white;
    color: #8d5b28;
}

.btn-send-outlined:hover {
    background-color: #e7892a;
    color: white;
}